<template>
    <el-card style="min-height: 45rem">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form label-width="10rem" style="padding: 2rem;width: 30rem">
            <el-form-item label="广告类型">
              <el-radio-group @change="adTypeChange" v-model="form.ad_type">
                <el-radio v-for="(item,key) in adType" :key="key" :label="item.value">{{item.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="素材">
              <y_upload_img v-if="form.ad_type === adType.image.value" v-model="form.content"></y_upload_img>
              <y_upload_video v-if="form.ad_type === adType.video.value" v-model="form.content"></y_upload_video>
            </el-form-item>
            <el-form-item label="视频展示高度" v-if="form.ad_type === adType.video.value">
              <el-input-number v-model="form.video_height"></el-input-number>
              <div class="y-desc">默认展示 宽600 * 高600</div>
              <div class="y-desc">此处可根据自身视频自行调整高度数值 = 600 / 视频宽 * 视频高</div>
            </el-form-item>
            <el-form-item label="跳转链接" v-if="form.ad_type === adType.image.value">
              <y_choose_link v-model="form.link"></y_choose_link>
            </el-form-item>
            <el-form-item label="启用">
              <el-switch v-model="form.enable"></el-switch>
            </el-form-item>
            <el-form-item>
              <el-button @click="submit" type="primary">保存</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

    </el-card>
</template>

<script>
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
import y_upload_video from "@/components/y-form/y-upload/y_upload_video";
import y_choose_link from "@/components/shop/y_choose_link";

const AdTypeState = {
  video:{
    value:1,
    label:"视频",
  },
  image:{
    value:2,
    label:"图片链接",
  },
}
export default {
  name: "ad-popup",
  components: {
    y_choose_link,
    y_upload_video,
    y_upload_img,
  },
  data() {
    return {
      adType:AdTypeState,
      form: {
        ad_type: 1,
        content:"",
        link:"",
        enable:false,
        video_height:0
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    adTypeChange(){
      this.form.content = "";
    },
    load() {
      this.$u.api.shop.sys.adPopup().then(res => {
        this.form = res;
      })
    },
    submit() {
      this.$u.api.shop.sys.adPopupEdit(this.form).then(() => {
        this.$message.success("操作成功");
      })
    }
  }
}
</script>

<style scoped>

</style>